import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Breadcrumb from "../components/Breadcrumb"
import ListMattress from "../components/ListMattress"
import Seo from "../components/Seo/Seo"
import { getVariables } from "../lib/utils"

import { graphql } from "gatsby"

export const query = graphql`
    query ($slug: [String!]) {
        datoCmsMattressCertification(slug: {in: $slug}, locale: {eq: "en"}) {
            certificationName
            certificationUrl
            slug
            certificationDescriptionNode {
                childMarkdownRemark {
                    html
                }
            }
            certificationLogo {
                gatsbyImageData(imgixParams: {w: "100", h: "100", fit: "scale"})
            }
            seoMeta {
            title
            image {
                url(imgixParams: {h: "100", w: "100", fit: "scale"})
            }
            }
        }

        allDatoCmsMattress(
            filter: {
                locale: {eq: "en"},
                location: {
                    websiteUrl: {eq: "us.mattress.zone"}
                },
                certifications: {elemMatch: {slug: {in: $slug}}}
            },
            sort: {
                fields: name, 
                order: ASC
            }
            ) {
            nodes {
                name
                slug
                location {
                    websiteUrl
                }
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "125", w: "250", colors: 10, cornerRadius: "10,10,10,10", mask: "corners"}
                        placeholder: BLURRED
                    )
                }
                id
                brand {
                    slug
                }
                mattressType {
                    slug
                }
            }
        }
    }`

const Certification = ({ location, data }) => {
    const variables = getVariables()

    const seoData = data.datoCmsMattressCertification.seoMeta

    let pageTitle = data.datoCmsMattressCertification.certificationName

    let breadcrumbData = [
        { 'link': `/${variables.certifications}`, 'label': variables.certifications },
        { 'link': null, 'label': pageTitle }
    ]

    return (
        <Layout>
            <Seo
                title={seoData.title}
                description={seoData.description}
                image={seoData.image.url}
                pathname={`/${variables.certification}/${data.datoCmsMattressCertification.slug}`}
            />

            <main className="bg-zone-1">
                <div className="container mx-auto px-4 xl:px-0">
                    <PageTitle
                        newestDate={null}
                        title={pageTitle}
                        link={null}
                        category={variables.certification} />
                </div>
                <div className="container mx-auto">
                    <Breadcrumb items={breadcrumbData} />
                </div>
                <div className="container mx-auto px-4 xl:px-0">
                    <div className="wrap-certifications">
                        <div dangerouslySetInnerHTML={{ __html: data.datoCmsMattressCertification.certificationDescriptionNode.childMarkdownRemark.html }} />
                    </div>
                </div>
                <ListMattress className="bg-zone-3 py-12 px-4 xl:px-0" container={true} mattresses={data.allDatoCmsMattress.nodes} />

            </main>
        </Layout>
    )
}

export default Certification

